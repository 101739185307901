<template>
  <div class="container page">
    <div class="header">
      <van-nav-bar :title="$t('recharge.recharge')" class="nav-bar">
        <template #left>
          <van-icon name="arrow-left" color="#fff" @click="back()" />
        </template>
      </van-nav-bar>
      <div class="info">
        <p class="title">
          {{ $t("recharge.curr_balance") }}({{ $t("my.currency") }})
        </p>
        <p class="value">{{ this.balance }} K</p>
      </div>
      <div class="content recharge">
        <label>{{ $t("recharge.recharge_tishi") }}</label>
<!--        <van-form @submit="onSubmit">-->
<!--          <div class="form-item">-->
<!--            <div class="form-item-title">{{ $t("recharge.input_money") }}</div>-->
<!--            <div style="height: 65px">-->
<!--              <van-field-->
<!--                v-model="money"-->
<!--                name="money"-->
<!--                :label="$t('my.currency')"-->
<!--                :placeholder="$t('recharge.input_money')"-->
<!--              />-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="rechargeItemTit">{{ $t("recharge.choose_amount") }}</div>-->
<!--			<div class="rechargeBox">-->
<!--				<div class="rechargeitem" v-for="(item,key) in rechargeData" :key="key" @click="setAmount(item)">{{ item }}K</div>-->
<!--			</div>-->
<!--          <div class="form-item">-->
<!--            <div class="form-item-title">{{ $t("recharge.pay_way") }}</div>-->
<!--            <div>-->
<!--              <van-radio-group v-model="pay_way">-->
<!--				<van-radio name="Qepay_QR">{{ pay_way2 }}</van-radio>-->
<!--                <van-radio name="Qepay_B2C">{{ pay_way1 }}</van-radio>-->
<!--                &lt;!&ndash; <van-radio name="2">OpplePay</van-radio> &ndash;&gt;-->
<!--              </van-radio-group>-->
<!--            </div>-->
<!--            <div style="margin: 16px">-->
<!--              <van-button round block type="info" native-type="submit"-->
<!--                >{{ $t("recharge.recharge_submit") }}</van-button-->
<!--              >-->
<!--            </div>-->
<!--          </div>-->
<!--        </van-form>-->
      </div>
    </div>
  </div>
</template>
  
<script>
import Vue from "vue";
import { Form } from "vant";
import { Field } from "vant";
import { RadioGroup, Radio } from "vant";
Vue.use(Form).use(Field).use(RadioGroup).use(Radio);
export default {
  data() {
    return {
      balance: 0,
      pay_way: "Qepay_QR",
      pay_way1: "Qepay_B2C",
      pay_way2: "Qepay_QR",
      win_money: 0,
      money: '',
      personalreport: {},
      rechargeData:[100,200,500,1000,3000,5000,10000,20000,30000]
    };
  },
  mounted() {
    this.balance = this.$route.params.balance;
  },
  methods: {
    back() {
      return window.history.back();
    },
    //拉起支付
    onSubmit(values) {
      const money = values.money;
      if (money <= 0) {
        this.$toast(this.$t("reservation.money_err"));
        return;
      }
      this.$http({
        method: "post",
        data: {
          pay_way: this.pay_way,
          money: money,
        },
        url: "recharge",
      }).then((res) => {
        console.log(res);
        if (res.code === 200) {
          window.location.href = res.data.pay_url;
          //   this.personalreport = res.data;
          //   this.win_money =
          //     this.personalreport.win_money - this.personalreport.play_money;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    },

    getPersonalreport() {
      this.$http({
        method: "get",
        url: "user_get_personalreport",
      }).then((res) => {
        if (res.code === 200) {
          this.personalreport = res.data;
          this.win_money =
            this.personalreport.win_money - this.personalreport.play_money;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    },
	
	setAmount(data){
		if(data > 0){
			this.money = data * 1000;
		}
	}
  },
  created() {
    if (!localStorage.getItem("token")) {
      this.$router.push({ path: "/Login" });
    } else {
      this.getPersonalreport();
    }
  },
};
</script>
  
  <style lang='less' scoped>
@import "../../assets/css/base.css";
.container .header {
  background: linear-gradient(270deg, #f3c4b0, #ff976a);
}

.nav-bar{
  background:rgba(200, 100, 100, 0.0);
}

.recharge {
  padding: 10px 30px;
}

.van-cell {
  line-height: 65px !important;
}

.van-button {
  height: 87px !important;
}
.van-button__text {
  color: #fff !important;
}

/deep/.van-radio__icon {
  font-size: 30px !important;
}
/deep/.van-radio__label {
  margin-left: 25px !important;
  font-size: 25px !important;
}

/deep/.van-radio {
  height: 65px !important;
}

/deep/.van-field__label {
	font-size: 45px!important;
	color: #1F8FFB;
	font-weight: 700;
	width:25%;
}

/deep/.van-field__control {
	font-size: 1.1rem;
	font-weight: bold;
	color:#1F8FFB;
}

.form-item {
  margin-top: 40px;
}

.form-item-title {
  font-size: 36px;
  font-weight: bold;
  color: #999;
  margin-bottom: 20px;
}

.recharge span {
  font-size: 4vw;
  color: #868686;
  font-weight: 500;
}

.container .header .info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  padding-top: 10px;
  margin: auto;
}
.container .header .info .title {
  font-size: 25px;
  color: #e5e5e5;
}
.container .header .info .value {
  margin: 10px auto;
  color: #fff;
  font-size: 50px;
  border-bottom: 1px solid #fff;
}
.container .header .info .tip {
  font-size: 30px;
  color: #e5e5e5;
}
.container .content {
  flex: 1;
  background: #f2f2f5;
}

.rechargeItemTit {
	font-size: 1.28rem;
	font-weight: 700;
	color: #999;
	line-height: .4rem;
	margin: 15px 0;
}

.rechargeBox {
	flex-wrap: wrap;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 90%;
	margin: 1.2rem auto 0;
}

.rechargeitem {
	width: 5.18rem;
	height: 3.88rem;
	font-size: 1.1rem;
	font-weight: 700;
	line-height: 3.88rem;
	text-align: center;
	margin-right: 1rem;
	margin-bottom: 0.7rem;
	background: #fff;
	border-radius: 0.44rem;
	box-sizing: border-box;
	border: 0.02rem solid #1F8FFB;
	color: #1F8FFB;
}
</style>
  