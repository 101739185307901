<template>
  <div class="container page">
    <div class="header">
      <van-nav-bar :title="$t('agent.recordofday')" class="nav-bar">
        <template #left>
          <van-icon name="arrow-left" color="#fff" @click="back()"/>
        </template>
      </van-nav-bar>
    </div>

    <van-cell-group class="search">
      <van-field v-model="search_username" :placeholder="$t('agent.please_enter_member_name')" />
      <van-field
          readonly
          clickable
          name="calendar"
          :value="date_range"
          :placeholder="$t('agent.start_date')+'-'+$t('agent.end_date')"
          @click="showStartPicker = true"
      />
      <van-calendar v-model="showStartPicker" :allow-same-day="true"
                    type="range"
                    :min-date="minDate" @confirm="onConfirm" />
      <van-button round block type="info" @click="OnChange()">{{ $t('agent.search') }}</van-button>
    </van-cell-group>

    <div class="main">
      <van-tabs v-model="active" animated swipeable  @change="OnChange">
        <van-tab v-for="(v,key) in tabs" :key="key" :title="v" :name="key" ></van-tab>
      </van-tabs>

      <swiper class="video_swiper" ref="swiper" :options="swiperOption" @slideChange="itemChange">
        <swiper-slide v-for="(v,key) in tabs" :key="key">
          <div class="movie-list-tab">
            <van-pull-refresh v-model="isLoading" @refresh="onRefresh"  pulling-text=" " loosing-text=" " loading-text="...">
              <van-empty v-if="list.length === 0" :description="$t('withdraw.empty_data')" />
              <div v-else class="item_list" v-for="(v,key) in list" :key="key">
                <div class="topInfo">
                  <span class="username">{{v.username}}</span>
                  <span style="color: #07c160">{{v.money}}</span>
                </div>
                <div class="statusRow">
                  <span>{{v.update_time}}</span>
                  <span>{{v.status_text}}</span>
                </div>
                <div v-if="v.type==1 && v.desc!=='' && v.status==3" class="statusRow red" style="display: flex;">
                  <span style="text-align: right;">{{v.desc}}</span>
                </div>
                <div v-if="v.type==2 && v.desc!=='' && v.status==3" class="statusRow green" style="display: flex;">
                  <span style="text-align: right;">{{v.desc}}</span>
                </div>
              </div>
              <div style="margin: 10px auto;background: #fff;" >
                <van-pagination v-model="page" :total-items="total" :items-per-page="page_num" :prev-text="$t('agent.pre_page')" :next-text="$t('agent.next_page')"  mode="simple" @change="changePage" />
              </div>
            </van-pull-refresh>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      page:1,
      page_num:12,
      total:0,
      count:0,
      active: 0,
      tabs:[this.$t("agent.all"), this.$t("agent.withdraw"), this.$t("agent.recharge")],
      list:[],
      search_username:'',
      search_start_date:'',
      search_end_date:'',
      date_range:'',
      showStartPicker:false,
      swiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 0,
        slidesPerGroup : 1,
      }
    };
  },
  computed: {
    minDate () {
      const date = new Date();
      return new Date(date.getFullYear()-1, date.getMonth() , date.getDate());
    }
  },
  methods: {
    back(){
      return window.history.back();
    },
    onRefresh() {
      setTimeout(() => {
        this.isLoading = true;
        this.getUserTransactionsList();
        this.$toast(this.$t("reservation.refresh"));
        this.isLoading = false;
      }, 500);
    },
    getSubUserTransactionsList(){
      this.$http({
        method: 'get',
        data:{page:this.page,type:this.active, username:this.search_username,start_date:this.search_start_date,end_date:this.search_end_date},
        url: 'user_get_sub_transactions_list'
      }).then(res=>{
        if(res.code === 200){
          this.list = res.data.data;
          this.total = res.data.total;
          this.page = res.data.page;
          this.page_num = res.data.page_num;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    formatDate(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
    onConfirm(date) {
      const [start, end] = date;
      this.search_start_date = `${this.formatDate(start)}`;
      this.search_end_date = `${this.formatDate(end)}`;
      this.date_range = `${this.formatDate(start)} - ${this.formatDate(end)}`
      this.showStartPicker = false;
    },
    OnChange(){
      this.getSubUserTransactionsList();
    },
    itemChange(){
      this.active = this.$refs.swiper.swiper.activeIndex;
      this.OnChange()
    },
    changePage(page){
      this.page = page;
      this.getSubUserTransactionsList();
    }
  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getSubUserTransactionsList();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
::v-deep .van-pull-refresh__track .van-pull-refresh__head *{
  color: #000000;
  font-size: 35px;
}

::v-deep .van-loading__text {
  color: #000000;
  font-size: 35px;
}
.container .main{
  position: relative;
  overflow: auto;
  background-color: #f2f2f5;
  height: 100%;
  padding: 0 10px;
}

.item_list{
  padding: 15px 15px;
  margin: 30px 10px;
  background: #fff;
  border-radius: 10px;
  line-height: 60px;
}

.item_list .topInfo span{
  flex: 1;
  font-size: 35px;
  font-weight: 700;
  color: #ff253f;
}
.item_list .topInfo span.username{
  font-weight: initial;
  color: #9b9b9b;
}
.item_list .time span{
  flex: 1;
  font-size: 25px;
  font-weight: 500;
  color: #000;
}
.item_list .topInfo span:last-child{
  float: right;
}
.item_list .desc span{
  font-size: 25px;
  font-weight: 700;
  color: #9b9b9b;
}
.item_list .statusRow span{
  flex: 1;
}
.item_list .statusRow span:last-child{
  float: right;
}

.van-cell {
  font-size: 32px;
  line-height: 80px;
}
.search{
  margin: 10px 0px;
  padding: 15px;
}
.red{
  color: red;
}
.green{
  color: green;
}
</style>