import Vue from 'vue'
import VueRouter from 'vue-router'
//首页
import Home from '../pages/home/index.vue'/* 首页 */
import Mine from '../pages/mine/index.vue'/* 我的 */
import Choose from '../pages/choose/index.vue'/* 选妃 */
import List from '../pages/choose/list.vue'/* 选妃列表 */
import Profile from '../pages/choose/profile.vue'/* 选妃详情 */
import Video from '../pages/video/index.vue'/* 视频 */
import Game from '../pages/game/index.vue'/* 游戏 */
import Login from '../pages/login/index.vue'/* 登录 */
import Register from '../pages/login/register.vue'/* 注册 */
import ServiceOnline from '../pages/mine/ServiceOnline.vue'/* 客服列表 */
import ServicePage from '../pages/mine/ServicePage.vue'/* 客服详情界面 */
import Setting from '../pages/mine/Setting.vue'/* 设置 */
import Infomation from '../pages/mine/Infomation.vue'/* 基本信息 */
import Setname from '../pages/mine/Setname.vue'/* 修改姓名 */
import Language from '../pages/mine/Language.vue'/* 语言选择 */
import Setsex from '../pages/mine/Setsex.vue'/* 修改姓名 */
import Recharge from '../pages/mine/Recharge.vue'/* 充值 */
import SetPayPassword from '../pages/mine/SetPayPassword.vue'/* 修改提现密码 */
import SetLoginPassword from '../pages/mine/SetLoginPassword.vue'/* 修改提现密码 */
import Lottery from '../pages/lottery/index.vue'/* 彩票详情 */
import Notice from '../pages/mine/Notice.vue'/* 公告 */
import PlayVideo  from '../pages/video/PlayVideo'/* 视频播放页面 */
import Setbank  from '../pages/mine/Setbank'/* 视频播放页面 */
import BindCard  from '../pages/mine/BindCard'/* 绑定银行卡界面 */
import Withdraw  from '../pages/mine/Withdraw'/* 绑定银行卡界面 */
import Personalreport  from '../pages/mine/Personalreport'/* 个人表报 */
import GameRecord  from '../pages/mine/GameRecord'/* 游戏记录 */
import WithdrawRecord  from '../pages/mine/WithdrawRecord'/* 提现记录 */
import Agent  from '../pages/mine/Agent'/* 游戏记录 */
import Transactions from '../pages/mine/Transactions'
import Subordinate from '../pages/mine/Subordinate'
import RecordOfDay from '../pages/mine/RecordOfDay'


Vue.use(VueRouter)
const routes = [
    {path:'/',redirect:'/Home',component:Home,meta:{title:'Mico'}},
    {path:'/Home',name:'home',component:Home,meta:{title:'Mico'}},
	{path:'/Choose',name:'choose',component:Choose,meta:{title:'memilih selir'}},
	{path:'/List',name:'list',component:List,meta:{title:'Daftar pemilihan selir'}},
	{path:'/Profile',name:'profile',component:Profile,meta:{title:'Detail pemilihan selir'}},
    {path:'/Mine',name:'mine',component:Mine,meta:{title:'milikku'}},
    {path:'/Video',name:'video',component:Video,meta:{title:'video'}},
    {path:'/Game',name:'game',component:Game,meta:{title:'permainan'}},
    {path:'/Login',name:'login',component:Login,meta:{title:'Mico'}},
    {path:'/Register',name:'register',component:Register,meta:{title:'daftar'}},
    {path:'/ServiceOnline',name:'ServiceOnline',component:ServiceOnline,meta:{title:'layanan daring'}},
    {path:'/ServicePage',name:'ServicePage',component:ServicePage,meta:{title:'layanan daring'}},
    {path:'/Setting',name:'Setting',component:Setting,meta:{title:'mempersiapkan'}},
    {path:'/Infomation',name:'Infomation',component:Infomation,meta:{title:'Informasi dasar'}},
    {path:'/Setname',name:'Setname',component:Setname,meta:{title:'Ubah nama asli'}},
    {path:'/Setsex',name:'Setsex',component:Setsex,meta:{title:'memodifikasi jenis kelamin'}},
    {path:'/Language',name:'Language',component:Language,meta:{title:'Mico'}},
    {path:'/Recharge',name:'Recharge',component:Recharge,meta:{title:'mengisi ulang'}},
    {path:'/SetPayPassword',name:'SetPayPassword',component:SetPayPassword,meta:{title:'Manajemen kata sandi pembayaran'}},
    {path:'/SetLoginPassword',name:'SetLoginPassword',component:SetLoginPassword,meta:{title:'Masuk manajemen kata sandi'}},
    {path:'/Lottery',name:'Lottery',component:Lottery,meta:{title:'Mico'}},
    {path:'/Notice',name:'Notice',component:Notice,meta:{title:'pengumuman'}},
    {path:'/PlayVideo',name:'PlayVideo',component:PlayVideo,meta:{title:'pemutaran video'}},
    {path:'/Setbank',name:'Setbank',component:Setbank,meta:{title:'Isi kartu pembayaran'}},
    {path:'/BindCard',name:'BindCard',component:BindCard,meta:{title:'Isi kartu pembayaran'}},
    {path:'/Withdraw',name:'Withdraw',component:Withdraw,meta:{title:'menarik'}},
    {path:'/Personalreport',name:'Personalreport',component:Personalreport,meta:{title:'pernyataan pribadi'}},
    {path:'/WithdrawRecord',name:'WithdrawRecord',component:WithdrawRecord,meta:{title:'Catatan penarikan'}},
    {path:'/GameRecord',name:'GameRecord',component:GameRecord,meta:{title:'catatan tugas'}},
    {path:'/Agent',name:'Agent',component:Agent,meta:{title:'catatan tugas'}},
    {path:'/Transactions',name:'Transactions',component:Transactions,meta:{title:'tình tiết giao dịch'}},
    {path:'/Subordinate',name:'Subordinate',component:Subordinate,meta:{title:'Truy vấn thành viên cấp dưới'}},
    {path:'/RecordOfDay',name:'RecordOfDay',component:RecordOfDay,meta:{title:'Tra cứu hồ sơ giao dịch trong ngày'}},
];

//生成路由实例
const router = new VueRouter({
    routes
})
router.beforeEach((to,from,next) => {         //修改标题路由配置加上这个
    document.title = to.matched[0].meta.title
    next()
})

export default router